import React from 'react';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import { Layout } from '@components';
import styled from 'styled-components';
import { Main, media, mixins, theme } from '@styles';
const { colors } = theme;

const StyledcoinContainer = styled(Main)`
  max-width: 1000px;
`;
const StyledcoinHeader = styled.header`
  margin-bottom: 50px;
  .coinid {
    margin-right: 10px;
  }
`;
const StyledcoinContent = styled.div`
  margin-bottom: 100px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 2em 0 1em;
  }

  p {
    margin: 1em 0;
    line-height: 1.5;
    color: ${colors.lightSlate};
  }
`;
const StyledFeaturedImg = styled(Img)`
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
  border-radius: ${theme.borderRadius};
  position: relative;
  mix-blend-mode: multiply;
  filter: grayscale(100%) contrast(1) brightness(90%);
  ${media.tablet`
    object-fit: cover;
    width: auto;
    height: 100%;
    filter: grayscale(100%) contrast(1) brightness(80%);
  `};
`;
const StyledImgContainer = styled.a`
  ${mixins.boxShadow};
  grid-column: 6 / -1;
  width: 100%;
  grid-row: 1 / -1;
  position: relative;
  z-index: 1;
  border-radius: ${theme.radius + 1}px;
  ${media.tablet`height: 100%;`};
  ${media.thone`
    grid-column: 1 / -1;
    opacity: 0.25;
  `};
  ${StyledFeaturedImg} {
      background: transparent;
      filter: none;
    }
`;
const StyledcoinsHeader = styled.div`
  ${mixins.flexBetween};
  margin-bottom: 30px;
  width: 100%;
`;
const StyledGrid = styled.div`
  margin-top: 50px;

  .coins {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    position: relative;
    ${media.desktop`grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));`};
  }
`;
const StyledcoinsInner = styled.div`
  ${mixins.boxShadow};
  ${mixins.flexBetween};
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 2rem 1.75rem;
  height: 100%;
  border-radius: ${theme.borderRadius};
  transition: ${theme.transition};
  background-color: ${colors.lightNavy};
  header,
  a {
    width: 100%;
  }
`;

const coinTemplate = ({ data, location }) => {
  const { frontmatter, html } = data.markdownRemark;
  const { cover, title, date, coinid } = frontmatter;

  return (
    <Layout location={location}>
      <Helmet>
        <title>{title} | Alistair Elliott</title>
        <link rel="canonical" href="https://ake.nz" />
      </Helmet>

      <StyledcoinContainer>
        <span className="breadcrumb">
          <span className="arrow">&larr;</span>
          <Link to="/numismatics">All Coins</Link>
        </span>

        <StyledcoinHeader>
          <h1 className="medium-title">{title}</h1>
          <p className="subtitle">
            <time>
              {new Date(date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </time>
            <span>&nbsp;&mdash;&nbsp;</span>
            {coinid &&
              coinid.length > 0 &&
              coinid.map((coinid, i) => (
                <Link key={i} to={`/numismatics/coinid/${kebabCase(coinid)}/`} className="coinid">
                  #{coinid}
                </Link>
              ))}
          </p>
          <StyledGrid>
          <div>
          <StyledcoinsInner>
          <StyledcoinsHeader>
          <StyledImgContainer
            target="_blank"
            rel="nofollow noopener noreferrer">
            <StyledFeaturedImg fluid={cover.childImageSharp.fluid} alt={title} />
            </StyledImgContainer>
            </StyledcoinsHeader>
          </StyledcoinsInner>
          </div>
          </StyledGrid>
        </StyledcoinHeader>
        <StyledcoinContent dangerouslySetInnerHTML={{ __html: html }} />
      </StyledcoinContainer>
    </Layout>
  );
};

export default coinTemplate;

coinTemplate.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { slug: { eq: $path } }) {
      html
      frontmatter {
        title
        description
        date
        slug
        coinid
      cover {
        childImageSharp {
        fluid(maxWidth: 500, quality: 100,) {
        ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
